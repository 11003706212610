import {handleAllowedCharacters} from '@hconnect/adminconsole/src/common/utils/handleAllowedCharacters'
import {Channel, passwordlessStart, Product} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {InputMessage, InputTextField, PhoneNumberTextField} from '@hconnect/uikit/src/lib2'
import {Tab, Tabs} from '@material-ui/core'
import {Lock} from '@mui/icons-material'
import {Box, Button, CircularProgress, LinearProgress, Link, Typography} from '@mui/material'
import classNames from 'classnames'
import {isValidPhoneNumber} from 'libphonenumber-js'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'

import {api} from '../../api/api'
import {userCanUseADLogin} from '../../api/user'
import LoadingButton from '../../Components/LoadingButton'
import {PaperTitle} from '../../Components/PaperTitle'
import {clientSecrets, LoginType} from '../../constants'
import {useAuthFeatures} from '../../hooks/features/useAuthFeatures'
import {RegistrationType, useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'
import {getLocale} from '../../utils'

import {usePhoneNumber} from './hooks/usePhoneNumber'
import {useStyles} from './SignIn.styles'
import {formatIdentityServerReturnUrl} from './utils/formatIdentityServerReturnUrl'

const isCorrespondingFieldDisabled = ({
  loginType,
  mobileNumber,
  email
}: {
  loginType: LoginType
  mobileNumber: string
  email: string
}) => (loginType === LoginType.PHONE && !mobileNumber) || (loginType === LoginType.EMAIL && !email)

interface ButtonProps {
  loading?: boolean
  onSubmit(): void
  translationKey: string
}
const PrimaryButton: React.FC<ButtonProps> = ({loading, onSubmit, translationKey}) => {
  const {t} = useTranslation()
  const {
    globalState: {mobileNumber, email, loginType}
  } = useGlobalState()

  const disabled = loading || isCorrespondingFieldDisabled({loginType, mobileNumber, email})

  return (
    <LoadingButton
      onClick={onSubmit}
      loading={loading}
      data-test-id="authenticator-submit-identifier"
      fullWidth
      type="submit"
      color="primary"
      variant="outlined"
      startIcon={<Lock style={{color: '#FFFFFF'}} />}
      disabled={disabled}
    >
      {t(translationKey)}
    </LoadingButton>
  )
}

interface SecondaryButton {
  onSubmit(): Promise<void> | void
  loading?: boolean
  translationKey: string
  dataTestId: string
}

const SecondaryButton: React.FC<SecondaryButton> = ({
  onSubmit,
  loading,
  translationKey,
  dataTestId
}) => {
  const {classes} = useStyles()

  const {
    globalState: {loginType, mobileNumber, email, clientId}
  } = useGlobalState()

  const isCarbonBank = clientId === Product.CarbonBank

  const statusLinkClass = isCorrespondingFieldDisabled({loginType, mobileNumber, email})
    ? isCarbonBank
      ? classes.cbDisabledLink
      : classes.disabledLink
    : isCarbonBank
      ? classes.cbEnabledLink
      : classes.enabledLink

  if (loading) {
    return <CircularProgress size={24} />
  }

  return (
    <Button
      className={classNames(classes.linkButton, statusLinkClass)}
      variant="outlined"
      onClick={onSubmit}
      data-test-id={dataTestId}
    >
      {translationKey}
    </Button>
  )
}

interface ButtonCombinationProps {
  loading: boolean
  isPasswordlessFlow: boolean
  loginPasswordless(): Promise<void>
  loginPasswordful(): void
}

const ButtonCombination: React.FC<ButtonCombinationProps> = ({
  loading,
  loginPasswordless,
  loginPasswordful,
  isPasswordlessFlow
}) => {
  const {
    globalState: {clientId}
  } = useGlobalState()
  const {t} = useTranslation()
  return (
    <>
      {clientId === Product.OnSite ? (
        <Box style={{display: 'flex', flexDirection: 'column', gap: '12px', alignItems: 'center'}}>
          <PrimaryButton
            onSubmit={loginPasswordless}
            loading={isPasswordlessFlow && loading}
            translationKey={t('authenticator.signIn.passwordlessSmsButtonLabel')}
          />
          <Box textAlign="center">
            <SecondaryButton
              dataTestId={'button-secondary-pwful'}
              onSubmit={loginPasswordful}
              loading={!isPasswordlessFlow && loading}
              translationKey={t('authenticator.signIn.signInWithPassword')}
            />
          </Box>
        </Box>
      ) : (
        <Box style={{display: 'flex', flexDirection: 'column', gap: '12px', alignItems: 'center'}}>
          <PrimaryButton
            onSubmit={loginPasswordful}
            loading={!isPasswordlessFlow && loading}
            translationKey={t('authenticator.username.next')}
          />
          <Box textAlign="center" mb={2}>
            <SecondaryButton
              dataTestId={'button-secondary-pwless'}
              onSubmit={loginPasswordless}
              loading={isPasswordlessFlow && loading}
              translationKey={t('authenticator.signIn.passwordlessSmsButtonLabel')}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

interface StepUsernameProps {
  usernameTextFieldRef: React.MutableRefObject<HTMLInputElement | undefined>
  error: string | null
}

// eslint-disable-next-line complexity, @typescript-eslint/no-unused-vars
export const StepUsername: React.FC<StepUsernameProps> = ({usernameTextFieldRef, error}) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const history = useHistory()
  const {shouldDisplayOldLoginFlow, shouldDisplayRequestAccessForm} = useAuthFeatures()
  const location = useLocation<{intiateQuickCodeSignIn: boolean} | undefined>()

  const [loading, setLoading] = useState(false)
  const [inputEmailError, setInputEmailError] = useState<string | null>(null)
  const [inputMobileNumberError, setInputMobileNumberError] = useState<string | null>(null)
  const [quickCodeError, setQuickCodeError] = useState<string | null>(null)
  const {globalState, setGlobalState} = useGlobalState()
  const [isPasswordlessFlow, setIsPasswordlessFlow] = useState(false)
  const [triggerPasswordlessFlow, setTriggerPasswordlessFlow] = useState(false)
  const {fullPhoneNumber, isLoading, refetch} = usePhoneNumber(
    globalState.mobileNumber,
    setInputMobileNumberError,
    setInputEmailError,
    setTriggerPasswordlessFlow,
    isPasswordlessFlow
  )

  const isCarbonBank = globalState.clientId === Product.CarbonBank

  const {isPasswordlessSignInEnabled, isPasswordfulSignInEnabled} = globalState

  const emailSelected = globalState.loginType === LoginType.EMAIL
  const phoneSelected = globalState.loginType === LoginType.PHONE

  const emailHelperText = inputEmailError ? inputEmailError : null

  const mobileHelperText = inputMobileNumberError ? inputMobileNumberError : null

  const isHub = globalState.clientId === Product.Hub
  const isOnsite = globalState.clientId === Product.OnSite

  useEffect(() => {
    if (triggerPasswordlessFlow && !isLoading) {
      void onPasswordlessSignInSubmit(false)
      setTriggerPasswordlessFlow(false)
    }
  }, [triggerPasswordlessFlow, isLoading])

  // eslint-disable-next-line complexity
  const loginPasswordful = async (): Promise<void> => {
    setIsPasswordlessFlow(false)

    const valid = emailSelected
      ? globalState.email.includes('@') && handleAllowedCharacters(globalState.email)
      : isValidPhoneNumber(globalState.mobileNumber) || globalState.mobileNumberValid

    try {
      if (valid && emailSelected && globalState.identityServerReturnUrl) {
        const canUserADLoginResult = await userCanUseADLogin(api)(globalState.email)

        if (canUserADLoginResult.canUseAdLogin) {
          const validIdentityServerUrl = formatIdentityServerReturnUrl(
            globalState.identityServerReturnUrl instanceof URL
              ? globalState.identityServerReturnUrl
              : new URL(globalState.identityServerReturnUrl)
          )

          window.location.href = `${
            window.location.origin
          }/api/identity/api/aad/login?idsReturnUrl=${encodeURIComponent(
            validIdentityServerUrl
              ? validIdentityServerUrl.toString()
              : globalState.identityServerReturnUrl.toString()
          )}`
          return
        }
      }
    } catch (e) {
      console.log(e)
    }

    if (phoneSelected && !valid) {
      void refetch()
      return
    }

    if (valid) {
      setInputEmailError(null)
      setInputMobileNumberError(null)

      history.push(routes.Password)
      return
    }

    if (globalState.loginType === LoginType.EMAIL) {
      setInputMobileNumberError(null)
      setInputEmailError(t('authenticator.signIn.invalidEmail'))
    } else {
      setInputEmailError(null)
      setInputMobileNumberError(
        globalState.mobileNumber.startsWith('+')
          ? t('authenticator.signIn.invalidPhone')
          : t('authenticator.signIn.missingCountryCode')
      )
    }
  }

  useEffect(() => {
    if (location?.state?.intiateQuickCodeSignIn) {
      void onPasswordlessSignInSubmit()
    }
  }, [location?.state?.intiateQuickCodeSignIn])

  // eslint-disable-next-line complexity
  const onPasswordlessSignInSubmit = async (refetchPhoneNumber: boolean = true) => {
    setIsPasswordlessFlow(true)

    try {
      const {clientId, email, loginType, redirectUrl, country, lng, username} = globalState

      const isMobile = loginType === LoginType.PHONE

      const valid = emailSelected
        ? globalState.email.includes('@') && handleAllowedCharacters(globalState.email)
        : isValidPhoneNumber(
            refetchPhoneNumber ? globalState.mobileNumber : globalState.username
          ) || globalState.mobileNumberValid

      if (!valid) {
        if (globalState.loginType === LoginType.EMAIL) {
          setInputMobileNumberError(null)
          setInputEmailError(t('authenticator.signIn.invalidEmail'))
        } else {
          setInputEmailError(null)

          if (globalState.mobileNumber.startsWith('+')) {
            setInputMobileNumberError(t('authenticator.signIn.invalidPhone'))
          } else {
            refetchPhoneNumber
              ? void refetch()
              : setInputMobileNumberError(t('authenticator.signIn.missingCountryCode'))
          }
        }
        return
      } else {
        setInputEmailError(null)
        setInputMobileNumberError(null)
      }

      setLoading(true)

      try {
        if (
          emailSelected &&
          globalState.email.includes('@') &&
          handleAllowedCharacters(globalState.email) &&
          globalState.identityServerReturnUrl
        ) {
          const canUserADLoginResult = await userCanUseADLogin(api)(globalState.email)

          if (canUserADLoginResult.canUseAdLogin) {
            const validIdentityServerUrl = formatIdentityServerReturnUrl(
              globalState.identityServerReturnUrl instanceof URL
                ? globalState.identityServerReturnUrl
                : new URL(globalState.identityServerReturnUrl)
            )

            window.location.href = `${
              window.location.origin
            }/api/identity/api/aad/login?idsReturnUrl=${encodeURIComponent(
              validIdentityServerUrl
                ? validIdentityServerUrl.toString()
                : globalState.identityServerReturnUrl.toString()
            )}`
            return
          }
        }
      } catch (e) {
        console.log(e)
      }

      const response = await passwordlessStart(api)({
        client_id: clientId,
        client_secret: clientSecrets[clientId],
        mobile_number: isMobile ? fullPhoneNumber || username : null,
        email: isMobile ? null : email,
        redirect_uri: redirectUrl.href,
        country_code: country,
        channel: isMobile ? Channel.SMS : Channel.EMAIL,
        product: clientId,
        request_locale: lng || getLocale(),
        type: 'code'
      })

      setLoading(false)

      if (response.type === 'value') {
        const passwordlessStartToken = response.value.start_token
        setGlobalState((g) => ({
          ...g,
          passwordlessStartToken,
          quickCodeLastRequested: Date.now()
        }))

        history.push(routes.InsertQuickCode)
        return
      }
      if (response.type === 'error') {
        trackEvent('authError', {
          product: 'authenticator',
          date: new Date().toISOString(),
          errorCode: response.error.errorCode,
          component: 'StepUsername.tsx',
          endpoint: '/passwordless/start'
        })

        if (response.error.message.includes('valid')) {
          setQuickCodeError(t('authenticator.signIn.error.quickCodeDeviceVerification'))
          return
        }

        if (response.error.message.includes('wait')) {
          setQuickCodeError(t('authenticator.signIn.error.quickCodeTooSoon'))
          return
        }

        setQuickCodeError(t('authenticator.signIn.error.unknown'))
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
      setQuickCodeError(t('authenticator.signIn.error.unknown'))
    }
  }

  const SignInButtons = () => {
    if (isPasswordlessSignInEnabled && isPasswordfulSignInEnabled) {
      return (
        <ButtonCombination
          loading={loading || isLoading}
          isPasswordlessFlow={isPasswordlessFlow}
          loginPasswordless={onPasswordlessSignInSubmit}
          loginPasswordful={loginPasswordful}
        />
      )
    }

    if (isPasswordfulSignInEnabled) {
      return (
        <PrimaryButton
          onSubmit={loginPasswordful}
          loading={loading || isLoading}
          translationKey={t('authenticator.username.next')}
        />
      )
    }

    return (
      <PrimaryButton
        onSubmit={onPasswordlessSignInSubmit}
        loading={loading || isLoading}
        translationKey={t('authenticator.signIn.passwordlessSmsButtonLabel')}
      />
    )
  }

  if (isPasswordlessSignInEnabled === null || isPasswordfulSignInEnabled === null) {
    return (
      <>
        <LinearProgress />
      </>
    )
  }

  const usernameHidden = location.pathname !== routes.SignIn
  const handleRegistrationType = (e, registrationType: RegistrationType) => {
    e.preventDefault()
    history.push(
      registrationType === 'request-access' ? routes.RequestAccess : routes.CreateAccount
    )
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedInputValue = event.target.value.trim()
    const detectLoginType = () => {
      const isMobile = /^\d+$/.test(trimmedInputValue) || /^\+\d+$/.test(trimmedInputValue)
      return isMobile ? LoginType.PHONE : LoginType.EMAIL
    }

    if (detectLoginType() === LoginType.EMAIL) {
      setGlobalState((globalState) => ({
        ...globalState,
        email: trimmedInputValue,
        username: trimmedInputValue,
        loginType: LoginType.EMAIL,
        mobileNumber: ''
      }))
      return
    }

    setGlobalState((globalState) => ({
      ...globalState,
      mobileNumber: trimmedInputValue.startsWith('00')
        ? trimmedInputValue.replace('00', '+')
        : trimmedInputValue,
      mobileNumberValid: isValidPhoneNumber(trimmedInputValue),
      email: '',
      username: trimmedInputValue,
      loginType: LoginType.PHONE
    }))
  }

  const shouldDisplayEmailError = inputEmailError && emailHelperText
  const shouldDisplayMobileNumberError = inputMobileNumberError && mobileHelperText
  const tabLoginFlow = (
    <Tabs
      value={globalState.loginType === LoginType.EMAIL ? 0 : 1}
      TabIndicatorProps={{
        style: {
          display: 'none'
        }
      }}
      onChange={(_, value) =>
        setGlobalState((globalState) => ({
          ...globalState,
          loginType: value === 0 ? LoginType.EMAIL : LoginType.PHONE
        }))
      }
      className={classes.tabContainer}
      style={{marginBottom: '28px'}}
    >
      <Tab
        data-test-id="pillar-hconnect"
        label={t('authenticator.signIn.emailLabel')}
        className={classes.firstTab}
        classes={{
          selected: classes.tabSelected
        }}
      />
      <Tab
        data-test-id="pillar-hproduce"
        label={t('authenticator.signIn.phone')}
        className={classes.secondTab}
        classes={{
          selected: classes.tabSelected
        }}
      />
    </Tabs>
  )

  return (
    <form
      data-test-id="page-signin-username"
      className={classNames({[classes.hidden]: usernameHidden, [classes.signIn]: !usernameHidden})}
      onSubmit={(e) => e.preventDefault()}
    >
      <Box>
        <PaperTitle title={`${t('authenticator.signIn.title')}`} isCentered={false} />
        {shouldDisplayOldLoginFlow && tabLoginFlow}
        {!shouldDisplayOldLoginFlow && (
          <InputTextField
            inputRef={usernameTextFieldRef}
            name={globalState.loginType === LoginType.EMAIL ? 'username' : 'mobileNumber'}
            autoComplete={
              usernameHidden
                ? undefined
                : globalState.loginType === LoginType.EMAIL
                  ? 'username'
                  : 'tel'
            }
            value={
              globalState.loginType === LoginType.EMAIL
                ? globalState.email
                : globalState.mobileNumber
            }
            data-test-id="authenticator-input-email-and-mobile"
            onChange={handleOnChange}
            error={inputEmailError !== null || inputMobileNumberError !== null}
            label={t('authenticator.signIn.email')}
            onFocusCapture={() => {
              setQuickCodeError(null)
              setInputEmailError(null)
              setInputMobileNumberError(null)
            }}
          />
        )}
        {shouldDisplayOldLoginFlow && globalState.loginType === LoginType.EMAIL && (
          <InputTextField
            inputRef={usernameTextFieldRef}
            name={'username'}
            autoComplete={'username'}
            value={globalState.email}
            data-test-id="authenticator-input-email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setGlobalState({
                ...globalState,
                email: e.target.value.trim(),
                username: e.target.value.trim()
              })
            }
            error={inputEmailError !== null}
            label={t('authenticator.signIn.emailLabel')}
          />
        )}
        {shouldDisplayOldLoginFlow && globalState.loginType === LoginType.PHONE && (
          <PhoneNumberTextField
            name={'mobileNumber'}
            data-test-id="authenticator-input-mobile"
            onPhoneChange={(phoneNumber, isValidNumber) => {
              setGlobalState({
                ...globalState,
                mobileNumber: phoneNumber,
                username: phoneNumber,
                mobileNumberValid: isValidNumber
              })
            }}
            error={inputMobileNumberError !== null}
            placeholder={t('authenticator.signIn.phone')}
            InputLabelProps={{
              shrink: false,
              style: {
                fontSize: '14px',
                fontWeight: 500
              }
            }}
            InputProps={{
              style: {
                fontSize: 14
              }
            }}
          />
        )}
      </Box>
      {(shouldDisplayEmailError || shouldDisplayMobileNumberError || quickCodeError) && (
        <InputMessage
          message={
            shouldDisplayEmailError
              ? emailHelperText
              : shouldDisplayMobileNumberError
                ? mobileHelperText
                : quickCodeError || ''
          }
        />
      )}
      <Box style={{marginTop: '20px'}}>
        <SignInButtons />
      </Box>
      {((isHub && shouldDisplayRequestAccessForm) || isOnsite) && (
        <Box className={classes.registrationTypeContainer}>
          <Typography style={{color: '#4E565E'}}>
            {isHub
              ? t('authenticator.signIn.requestAccessText')
              : t('authenticator.signIn.createAccountText')}
          </Typography>
          <Link
            data-test-id="authenticator-quick-code"
            className={classNames(
              classes.linkButton,
              isCarbonBank ? classes.cbEnabledLink : classes.enabledLink
            )}
            onClick={(event) =>
              handleRegistrationType(event, isHub ? 'request-access' : 'create-account')
            }
            underline="always"
          >
            {isHub
              ? t('authenticator.signIn.requestAccessLink')
              : t('authenticator.signIn.createAccountLink')}
          </Link>
        </Box>
      )}
    </form>
  )
}
